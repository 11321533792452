<template>
    <div>
        <div class="side-menu">
            <aside class="menu">
                <p class="menu-label">Menu</p>
                <ul class="menu-list">
                    <!-- <li><a :class="{'is-active': currentTab == 'start'}">All Resources</a></li> -->
                    <li><router-link :class="{ 'is-active': currentTab == 'start' }" to="latest-news">News Home</router-link></li>
                    <!-- <li><router-link :class="{ 'is-active': currentTab == 'september-2024' }" to="september-2024">September 2024</router-link></li> -->
                    <!-- <li><router-link :class="{ 'is-active': currentTab == 'surveyors' }" to="surveyors">Accredited Surveyors</router-link></li> -->
                </ul>
            </aside>
        </div>

        <div class="dropdown-wrapper">
            <div @click="toggleDropdown" class="dropdown" :class="{ 'is-active': dropdownActive }">
                <div class="dropdown-trigger">
                    <button class="button" aria-haspopup="true" aria-controls="dropdown-menu3">
                        <span>Toggle Menu</span>
                        <span class="icon is-small">
                            <font-awesome-icon v-if="dropdownActive" class="icon" icon="chevron-up" />
                            <font-awesome-icon v-else class="icon" icon="chevron-down" />
                        </span>
                    </button>
                </div>
                <div class="dropdown-menu" id="dropdown-menu3" role="menu">
                    <div class="dropdown-content">
                        <router-link to="resources-home" class="dropdown-item">Resources Home</router-link>
                        <hr class="dropdown-divider" />
                        <router-link to="legislation" class="dropdown-item">Legislation</router-link>
                        <router-link to="tutorials" class="dropdown-item">Tutorials</router-link>
                        <router-link to="surveyors" class="dropdown-item">Accredited Surveyors</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { db } from '../main.js';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

export default {
    computed: {
        // user() {
        //   return this.$store.getters.getUser;
        // },
    },
    props: {
        currentTab: String
    },
    data: function() {
        return {
            dropdownActive: false
        };
    },
    methods: {
        toggleDropdown: function() {
            this.dropdownActive = !this.dropdownActive;
        }
    }
};
</script>

<style lang="scss">
.side-menu {
    width: 27%;
    max-width: 300px;
    margin-right: 3%;
    float: left;
}
.dropdown-wrapper {
    display: none;
    .dropdown {
        width: 100%;
        .dropdown-trigger {
            width: 100%;
            .button {
                width: 100%;
                &:focus {
                    outline: none;
                    border-color: #dbdbdb;
                }
                .is-small {
                    svg {
                        font-size: 0.6rem;
                    }
                }
            }
        }
        .dropdown-menu {
            width: 100%;
            .dropdown-content {
                .router-link-active {
                    background: #eee;
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .side-menu {
        display: none;
    }
    .dropdown-wrapper {
        display: block;
    }
}
</style>
